/* tslint:disable */
export const theme = {
  breakpoints: ['48em', '64em', '85.375em'],
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 72],
  fontSizes: [11, 12, 14, 16, 18, 20, 24, 26, 30, 32, 36],
  borderWidths: [0, '1px', '2px', '4px', '8px', '16px', '32px'],
  radii: [0, '2px', '4px', '8px', '16px', '9999px', '100%'],
  lineHeights: ['1.0', '1.25', '1.5'],
  measures: ['20em', '30em', '34em'],
  fonts: {
    serif: 'athelas, georgia, times, serif',
    sansSerif:
      'system-ui, -apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
    mono: 'Consolas, monaco, monospace',
  },
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    'tracked-tight': {
      letterSpacing: '-0.05em',
    },
    copy: {
      lineHeight: 1.5,
      maxWidth: '30em',
    },
    title: {
      lineHeight: 1.25,
      maxWidth: '30em',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  },
  borders: {
    neutral: '1px solid #919191',
    error: '2px solid #ff5e00',
    active: '2px solid #3e9814',
  },
  colors: {
    'ku-crimson': '#eb0000',
    folly: '#fe074e',
    'vivid-orange': '#ff5e00',
    'slimy-green': '#3e9814',
    'spanish-grey': '#919191',
    'slate-grey': '#54575f',
    'dark-red': '#e7040f',
    red: '#ff4136',
    'light-red': '#ff725c',
    'washed-red': '#ffdfdf',
    orange: '#ff6300',
    gold: '#ffb700',
    yellow: '#ffd700',
    'light-yellow': '#fbf1a9',
    'washed-yellow': '#fffceb',
    purple: '#5e2ca5',
    'light-purple': '#a463f2',
    'dark-pink': '#d5008f',
    'hot-pink': '#ff41b4',
    pink: '#ff80cc',
    'light-pink': '#ffa3d7',
    'dark-green': '#137752',
    green: '#19a974',
    'light-green': '#9eebcf',
    'washed-green': '#e8fdf5',
    navy: '#001b44',
    'dark-blue': '#00449e',
    blue: '#357edd',
    'light-blue': '#96ccff',
    'lightest-blue': '#cdecff',
    'washed-blue': '#f6fffe',
    black: '#000',
    'near-black': '#111',
    'dark-gray': '#333',
    'mid-gray': '#555',
    gray: '#777',
    silver: '#999',
    'light-silver': '#aaa',
    'moon-gray': '#ccc',
    'light-gray': '#eee',
    'near-white': '#f4f4f4',
    white: '#fff',
    'black-90': 'rgba(0,0,0,.9)',
    'black-80': 'rgba(0,0,0,.8)',
    'black-70': 'rgba(0,0,0,.7)',
    'black-60': 'rgba(0,0,0,.6)',
    'black-50': 'rgba(0,0,0,.5)',
    'black-40': 'rgba(0,0,0,.4)',
    'black-30': 'rgba(0,0,0,.3)',
    'black-20': 'rgba(0,0,0,.2)',
    'black-10': 'rgba(0,0,0,.1)',
    'black-05': 'rgba(0,0,0,.05)',
    'black-025': 'rgba(0,0,0,.025)',
    'black-0125': 'rgba(0,0,0,.0125)',
    'white-90': 'rgba(255,255,255,.9)',
    'white-80': 'rgba(255,255,255,.8)',
    'white-70': 'rgba(255,255,255,.7)',
    'white-60': 'rgba(255,255,255,.6)',
    'white-50': 'rgba(255,255,255,.5)',
    'white-40': 'rgba(255,255,255,.4)',
    'white-30': 'rgba(255,255,255,.3)',
    'white-20': 'rgba(255,255,255,.2)',
    'white-10': 'rgba(255,255,255,.1)',
    'white-05': 'rgba(255,255,255,.05)',
    'white-025': 'rgba(255,255,255,.025)',
    'white-0125': 'rgba(255,255,255,.0125)',
  },
  containerWidth: '128rem',
};
